.dashboard-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.search-bar-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
}
.search-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 800;
}
.search-button1 {
    padding: 10px 20px;
    background-color: #019EFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
    font-weight: 800;
}
.search-button.active {
    background-color: #019EFF;
}

.search-button:hover {
    background-color: #019EFF;
}

.search-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.input-field {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.select-field {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80%;
    
}

.driver-details {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}
.driverdetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    margin:10px
}
.driverdetails:hover {
    background-color: rgb(200, 200, 200);
}
.driver-details h3 {
    margin-bottom: 10px;
}

.driver-details p {
    margin: 5px 0;
}

/* General container styling */
.profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form group styling for label and input alignment */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

/* Styling the input and select fields */
.form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

/* Save button styling */
.save-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .profile-container {
        padding: 15px;
    }

    .form-control {
        font-size: 14px;
    }

    .save-button {
        width: 100%;
        padding: 12px;
    }
}