:root {
    --color-title: #fff9ff;
}

footer {
    left: 0;
    bottom: 12px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
        url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    padding: 4rem 0 0 0;
    min-height: 100%;
    z-index: 1;
}

section.top>img {
    height: 50px;
    margin: 0 auto 50px;
}

section {
    position: relative;
    margin: 0 30px;
    min-width: 90%;
   
}

section.top {
    /*display: grid;*/
    border-bottom: 2px solid rgb(255 255 255 / 20%);
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    
   
}

.sociallinks {
    height: 30px;
    width: 30px;
}
.bottom {
gap: 8px;
padding: 10px 0;
display: flex;
flex-direction: row;
width: 100%;
}

section.top .links {
    /*display: grid;*/
    
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 36px;
    color: white;
}

.links-column {
    display: grid;
    gap: 10px;
    text-align: center;
    align-items: center;
   
    

}

section h2 {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 400;
    color: var(--color-title);
    text-align: center;
}

section p {
    margin: 0;
    color: white;
}

.legal>a {
    margin: 0 0 0 4px;
    color: white;
    
}

.legal,
.copyright {
    font-size: 12px;

}

.legal>span {
    margin-right: 10px;

}
.legal{
    display: flex;
    gap:20px
}
section.top .socials {
    position: absolute;
    left: 50%;
    bottom: 10px;
    translate: -50% 0;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 56px;
    cursor: pointer;
}

section.top .socials>a {
    font-size: 20px;
    background: rgb(45 118 232 / 25%);
    color: var(--color-title);
    width: 36px;
    height: 36px;
    display: grid;
    place-items: center;
    border-radius: 50%;

}

.socials-column :is(h2, p) {
    display: none;
}

@media (width > 400px) {
    .links-column {
        text-align: left;
    }

    section.top .links {
        grid-template-columns: repeat(2, 1fr);
        margin-right: 50px;
    }

    section.bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: 20px;
        text-align: left;
    }

    section.top .socials {
        left: 0;
        translate: 0;
    }

    section.top>img {
        margin: 0 0 50px;
    }
    
}

@media (width >=640px) {
    section {
        margin: 0 auto;
        max-width: 100%;
        padding: 0;
    }

    section.top .links {
       /* grid-template-columns: repeat(3, 1fr);*/
      

    }
    section.top{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    section.top .socials {
        position: static;
    }

    section.bottom {
        justify-content: flex-start;
    }

    .socials-column :is(h2, p) {
        display: block;
    }
    
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    text-align: center;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.company-info {
    max-width: 350px;
    margin: 0 auto;
}

.footer-logo {
    height: 60px;
    width: auto;
    margin-bottom: 1.5rem;
    filter: brightness(0) invert(1);
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.company-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    color: #fff;
    opacity: 0.9;
    text-align: center;
}

.social-links {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.social-links a {
    color: #fff;
    font-size: 1.5rem;
    transition: all 0.3s ease;
}

.social-links a:hover {
    color: #4CAF50;
    transform: translateY(-3px);
}

.quick-links h3,
.contact-info h3 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    position: relative;
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.quick-links h3::after,
.contact-info h3::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -0.5rem;
    width: 50px;
    height: 2px;
    background: #4CAF50;
    transform: translateX(-50%);
}

.quick-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.quick-links ul li {
    margin-bottom: 0.8rem;
}

.quick-links ul li a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
    opacity: 0.9;
    font-size: 1rem;
}

.quick-links ul li a:hover {
    color: #4CAF50;
    padding-left: 5px;
    opacity: 1;
}

.contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.contact-details p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: #fff !important;
    opacity: 1;
    font-size: 1rem;
    text-align: center;
    width: 100%;
}

.contact-details p svg {
    font-size: 1.2rem;
    color: #4CAF50;
    flex-shrink: 0;
}

.contact-details p span {
    color: #fff !important;
    opacity: 0.9;
    font-size: 1rem;
    word-break: break-word;
    line-height: 1.5;
    max-width: 100%;
}

.contact-info h3 {
    color: #fff !important;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 1.3rem;
}

.footer-bottom {
    margin-top: 4rem;
    padding: 1.5rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
}

.footer-bottom-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.footer-bottom p {
    margin: 0;
    color: #fff;
    opacity: 0.9;
    font-size: 0.9rem;
}

.legal-links {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.legal-links a {
    color: #fff;
    text-decoration: none;
    opacity: 0.9;
    transition: all 0.3s ease;
    font-size: 0.9rem;
}

.legal-links a:hover {
    color: #4CAF50;
    opacity: 1;
}

@media (max-width: 768px) {
    .footer {
        padding: 3rem 0 0 0;
    }

    .footer-content {
        grid-template-columns: 1fr;
        gap: 3rem;
        padding: 0 1.5rem;
    }

    .footer-section {
        margin-bottom: 2rem;
    }

    .contact-details p {
        font-size: 0.95rem;
    }

    .contact-details p span {
        font-size: 0.95rem;
    }

    .footer-logo {
        height: 50px;
    }

    .social-links a {
        font-size: 1.3rem;
    }

    .legal-links {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 2rem 0 0 0;
    }

    .footer-content {
        padding: 0 1rem;
    }

    .footer-logo {
        height: 50px;
    }

    .social-links a {
        font-size: 1.3rem;
    }

    .legal-links {
        gap: 1rem;
        flex-direction: column;
    }

    .contact-details p {
        font-size: 0.9rem;
    }

    .contact-details p span {
        font-size: 0.9rem;
    }
}