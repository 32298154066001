/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}

/* Contact Container */
.contact-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

/* Form Styling */
.contact-form {
    background: white;
    padding: 2.5rem;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    margin-top: 3rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
    font-size: 0.95rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.8rem 1rem;
    border: 1.5px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: #f8f9fa;
    color: #333;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #1a237e;
    background: white;
    box-shadow: 0 0 0 4px rgba(26, 35, 126, 0.1);
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
}

.submit-button {
    width: 100%;
    padding: 1rem;
    background: #1a237e;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.submit-button:hover {
    background: #151b4f;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(26, 35, 126, 0.2);
}

.submit-button:active {
    transform: translateY(0);
}

/* Form error states */
.form-group input.error,
.form-group textarea.error {
    border-color: #dc3545;
    background: #fff8f8;
}

.error-message {
    color: #dc3545;
    font-size: 0.85rem;
    margin-top: 0.3rem;
}

/* Success message */
.success-message {
    text-align: center;
    color: #28a745;
    padding: 1rem;
    border-radius: 8px;
    background: #f0fff4;
    margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-container {
        padding: 15px;
        margin: 20px;
    }

    .contact-container h2 {
        font-size: 22px;
    }

    .contact-form {
        padding: 1.5rem;
        margin-top: 2rem;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .form-group input,
    .form-group textarea {
        padding: 0.7rem 0.9rem;
        font-size: 0.95rem;
    }

    .submit-button {
        padding: 0.9rem;
    }
}

@media (max-width: 480px) {
    .contact-container {
        padding: 1.2rem;
    }

    .contact-container h2 {
        font-size: 2rem;
    }

    .form-group label {
        font-size: 0.9rem;
    }

    .form-group input,
    .form-group textarea {
        font-size: 0.9rem;
    }
}

.contact-page {
  min-height: 100vh;
  width: 100%;
  padding: 100px 20px 60px;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-header {
  text-align: center;
  margin-bottom: 50px;
}

.contact-header h1 {
  font-size: 3rem;
  color: #1a237e;
  margin-bottom: 1rem;
  font-weight: 700;
}

.contact-header p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.contact-card {
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  height: 100%;
  position: relative;
  overflow: hidden;
}

.contact-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, rgba(26, 35, 126, 0.7), rgba(26, 35, 126, 0.3));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.contact-card:hover::before {
  opacity: 1;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.icon-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
  background: #f8f9fa;
  border: 2px solid rgba(26, 35, 126, 0.1);
}

.icon {
  font-size: 2rem;
  transition: all 0.3s ease;
}

.contact-card:hover .icon-wrapper {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-info h3 {
  font-size: 1.25rem;
  color: #1a237e;
  margin: 0 0 0.75rem 0;
  font-weight: 600;
}

.contact-info p {
  font-size: 1rem;
  color: #666;
  margin: 0;
  line-height: 1.5;
  max-width: 100%;
  word-break: break-word;
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1rem;
  }

  .contact-card {
    padding: 2rem 1.5rem;
  }

  .icon-wrapper {
    width: 70px;
    height: 70px;
    margin-bottom: 1.25rem;
  }

  .icon {
    font-size: 1.75rem;
  }

  .contact-info h3 {
    font-size: 1.2rem;
  }

  .contact-info p {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .contact-grid {
    padding: 0.5rem;
  }

  .contact-card {
    padding: 1.75rem 1.25rem;
  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
  }

  .icon {
    font-size: 1.5rem;
  }

  .contact-info h3 {
    font-size: 1.1rem;
  }

  .contact-info p {
    font-size: 0.9rem;
  }
}