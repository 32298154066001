/* Basic styles */
@keyframes appear3 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.header-wrapper {
    min-height: 90vh;
    width: 100%;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    padding: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.header-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top right, rgba(25, 118, 210, 0.05), transparent 60%);
    pointer-events: none;
}

.header-content {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    padding: 0 1rem;
}

.text-container {
    flex: 1;
    max-width: 600px;
}

.company-tag {
    font-size: 1.2rem;
    color: #1976d2;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

h1 {
    font-size: 3.5rem;
    background: linear-gradient(135deg, #1976d2, #2196f3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 1rem 0;
    line-height: 1.2;
    font-weight: 800;
}

.subtitle {
    font-size: 1.5rem;
    color: #455a64;
    margin-bottom: 1.5rem;
    font-weight: 500;
}

.divider {
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #1976d2, #2196f3);
    margin: 1.5rem 0;
    border-radius: 2px;
}

.description {
    color: #546e7a;
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 2rem;
}

.cta-buttons {
    display: flex;
    gap: 1rem;
}

.primary-btn, .secondary-btn {
    padding: 1rem 2rem;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.primary-btn {
    background: linear-gradient(135deg, #1976d2, #2196f3);
    color: white;
    border: none;
}

.primary-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(33, 150, 243, 0.3);
}

.secondary-btn {
    background: transparent;
    color: #1976d2;
    border: 2px solid #1976d2;
}

.secondary-btn:hover {
    background: rgba(25, 118, 210, 0.1);
    transform: translateY(-2px);
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animation-wrapper {
    width: 100%;
    max-width: 600px;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
    h1 {
        font-size: 3rem;
    }
    
    .subtitle {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 768px) {
    .header-wrapper {
        width: 100%;
        padding: 4rem 1rem;
    }

    .header-content {
        width: 100%;
        padding: 0;
        flex-direction: column;
        text-align: center;
        gap: 3rem;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.2rem;
    }

    .description {
        font-size: 1rem;
        text-align: center;
    }

    .divider {
        margin: 1.5rem auto;
    }

    .cta-buttons {
        flex-direction: column;
        width: 100%;
        max-width: 300px;
    }

    .primary-btn, .secondary-btn {
        width: 100%;
    }

    .animation-wrapper {
        max-width: 400px;
    }
}

@media screen and (max-width: 480px) {
    .header-wrapper {
        padding: 3rem 1rem;
    }

    h1 {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1.1rem;
    }

    .company-tag {
        font-size: 1rem;
    }
}