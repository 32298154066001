body {
    margin: 0;
    font-family: Arial, sans-serif;
    background: #f0f2f5;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background: linear-gradient(135deg, #71b7e6, #9b59b6);
}

.login-form {
    background: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    width: 30%;
    text-align: center;
}

.login-form h2 {
    margin-bottom: 20px;
    font-weight: 300;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #333;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.login-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #9b59b6;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
}

.login-button:hover {
    background: #8e44ad;
}