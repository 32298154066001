/* General Reset */

/* Navbar Container */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    padding: 1.2rem 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar.scrolled {
    padding: 1rem 0;
    background: rgba(255, 255, 255, 0.99);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.navbar-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-container img {
    height: 60px;
    width: auto;
    object-fit: contain;
}

/* Reset all transform and visibility properties for web view */
.nav-links {
    display: flex !important;
    gap: 3.5rem;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
    position: static;
    background: none;
    height: auto;
    width: auto;
}

.nav-links li {
    margin: 0;
    padding: 0;
    border: none;
}

.nav-link {
    color: #333 !important;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.15rem;
    position: relative;
    padding: 0.8rem 0;
    transition: all 0.3s ease;
    display: inline-block;
    width: auto;
    text-align: center;
    background: none;
}

.nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: #1a237e;
    transition: width 0.3s ease;
    border-radius: 2px;
}

.nav-link:hover {
    color: #1a237e !important;
}

.nav-link:hover::after {
    width: 100%;
}

.nav-link.active {
    color: #1a237e !important;
}

.nav-link.active::after {
    width: 100%;
    background: #1a237e;
}

/* Hamburger Menu */
.menu-toggle {
    display: none;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .navbar {
        padding: 0.5rem 0;
    }

    .navbar-container {
        padding: 0 1rem;
    }

    .logo-container img {
        height: 45px;
    }

    .menu-toggle {
        display: flex;
    }

    .menu-toggle.open .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .menu-toggle.open .bar:nth-child(2) {
        opacity: 0;
    }

    .menu-toggle.open .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-links {
        position: fixed !important;
        top: 70px !important;
        left: 0 !important;
        right: 0 !important;
        height: calc(100vh - 70px) !important;
        background: white !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        gap: 0 !important;
        padding-top: 1rem !important;
        transform: translateX(100%) !important;
        opacity: 0 !important;
        visibility: hidden !important;
        display: flex !important;
    }

    .nav-links.open {
        transform: translateX(0) !important;
        opacity: 1 !important;
        visibility: visible !important;
    }

    .nav-links li {
        width: 100%;
        border-bottom: 1px solid #eee;
    }

    .nav-link {
        font-size: 1.2rem;
        padding: 1rem 2rem;
        width: 100%;
        text-align: center;
    }

    .nav-link.active {
        background: #1a237e;
        color: white !important;
    }

    .nav-link:hover {
        background: #f5f5f5;
    }

    .nav-link.active:hover {
        background: #1a237e;
    }
}

@media screen and (max-width: 480px) {
    .logo-container img {
        height: 40px;
    }

    .nav-link {
        font-size: 1.1rem;
        padding: 0.8rem 1.5rem;
    }
}