@keyframes appear {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes appear2 {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes appear3 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.product-title-section {
  padding: 4rem 2rem 2rem;
  background-color: #f8f9fa;
}

.product-title-section .section-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.product-title-section h2 {
  font-size: 4.5rem;
  color: #1a237e;
  margin-bottom: 1rem;
  font-weight: 700;
}

.product-title-section p {
  font-size: 1.2rem;
  color: #546e7a;
}

@media (max-width: 768px) {
  .product-title-section {
    padding: 3rem 1.5rem 1.5rem;
  }
  
  .product-title-section h2 {
    font-size: 2rem;
  }
  
  .product-title-section p {
    font-size: 1rem;
  }
}

.product-page {
  width: 100%;
  overflow-x: hidden;

  margin-top: 6rem;
}

/* Hero Section */
.product-hero {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(139, 139, 139, 0.8), rgba(139, 139, 139, 0.8)), 
                    url('https://images.unsplash.com/photo-1519003722824-194d4455a60c?q=80&w=1975&auto=format&fit=crop');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 2.5rem;
  font-weight: 800;
  color: rgb(0, 0, 0);
  margin: 0;
  line-height: 1.5;
  margin-top: -20%;
  width: 100%;

}

.hero-logo {
  max-width: 900px;
  height: auto;
  margin-top: -20%;
}

/* Overview Section */
.product-overview {
  width: 100%;
  padding: 6rem 2rem;
  background: #ffffff;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.overview-content {
  width: 100%;
  text-align: center;
}

.overview-text {
  width: 100%;
  margin: 0 auto;
}

.overview-text h2 {
  font-size: 3.5rem;
  color: #1a237e;
  margin-bottom: 1.5rem;
  font-weight: 800;
}

.overview-text p {
  font-size: 1.2rem;
  color: #546e7a;
  margin-bottom: 2rem;
  font-weight: 800;
}

.key-features {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-icon {
  font-size: 1.5rem;
}

.overview-image {
  flex: 1;
}

.overview-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);
}

/* Features Grid */
.product-features {
  width: 100%;
  padding: 6rem 2rem;
  background: #f8f9fa;
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
}

.section-header h2 {
  font-size: 2.5rem;
  color: #1a237e;
  margin-bottom: 1rem;
}

.section-header p {
  font-size: 1.2rem;
  color: #546e7a;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.feature-card h3 {
  font-size: 1.5rem;
  color: #1a237e;
  margin: 1rem 0;
}

.feature-card p {
  color: #546e7a;
  line-height: 1.6;
}

/* CTA Section */
.product-cta {
  width: 100%;
  padding: 6rem 2rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), 
                    url('https://images.unsplash.com/photo-1494412685616-a5d310fbb07d?q=80&w=1770&auto=format&fit=crop');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
}

.cta-content {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.primary-button, .secondary-button {
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primary-button {
  background: white;
  color: #1a237e;
  border: none;
}

.secondary-button {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.primary-button:hover, .secondary-button:hover {
  transform: translateY(-2px);
}

/* Mobile optimization for background images */
@media (max-width: 768px) {
  .product-hero,
  .product-cta {
    background-attachment: scroll;
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .overview-content {
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .product-hero {
    padding: 4rem 1.5rem;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .overview-content {
    flex-direction: column;
    text-align: center;
  }

  .key-features {
    gap: 1rem;
  }

  .overview-text h2 {
    font-size: 2rem;
  }

  .cta-buttons {
    flex-direction: column;
  }

  .primary-button, .secondary-button {
    width: 100%;
  }

  .hero-logo {
    max-width: 200px;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .section-header h2 {
    font-size: 2rem;
  }
}

/* Add these styles to your existing CSS */

.services-section {
  margin-bottom: 4rem;
}

.service-type-header {
  font-size: 2rem;
  color: #1a237e;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 3px solid #e3f2fd;
  position: relative;
}

.service-type-header::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100px;
  height: 3px;
  background: #1a237e;
}

.d2c-grid {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.b2b-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.d2c-card {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  border: 1px solid #e3f2fd;
}

.b2b-card {
  background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
  border: 1px solid #e3f2fd;
}

.feature-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.feature-card p {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .service-type-header {
    font-size: 1.75rem;
    text-align: center;
  }

  .service-type-header::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 480px) {
  .service-type-header {
    font-size: 1.5rem;
  }
}
