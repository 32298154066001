/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Montserrat:wght@600&display=swap');
@keyframes appear {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes appear2 {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
:root {
    --primary-color: #019EFF;
    --secondary-color: #019EFF;
    --accent-color: #28a745;
    --background-color: #f8f9fa;
    --text-color: #343a40;
    --light-text: #6c757d;
    --border-radius: 10px;
    --transition-speed: 0.3s;
}


.about-page {
  background-color: #f8f9fa;
  margin-top: 6rem;
}

/* Hero Section with Parallax */
.about-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&w=2070&q=80') fixed;
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.hero-content p {
  font-size: 1.5rem;
  opacity: 0.9;
}

/* Section Containers */
.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
}

/* Company Section */
.company-section {
  background: white;
}

.company-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4rem;
  align-items: center;
}

.company-image {
  text-align: center;
}

.company-logo {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.company-text h2 {
  font-size: 2.5rem;
  color: #1a237e;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.company-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 2rem;
}

.highlight {
  color: #1a237e;
  font-weight: 600;
}

.features-list {
  display: grid;
  gap: 1rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.1rem;
  color: #444;
}

.feature-item .icon {
  color: #4CAF50;
  font-size: 1.3rem;
}

/* Stats Section with Parallax */
.stats-section {
  background: linear-gradient(135deg, rgba(26, 35, 126, 0.95), rgba(13, 71, 161, 0.95)),
    url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=2070&q=80') fixed;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  text-align: center;
}

.stat-card {
  padding: 2rem;
  background: rgba(255,255,255,0.1);
  border-radius: 16px;
  backdrop-filter: blur(5px);
}

.stat-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #4CAF50;
}

.stat-card h3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.stat-card p {
  font-size: 1.1rem;
  opacity: 0.9;
}

/* Values Section */
.values-section {
  background: white;
  text-align: center;
}

.values-section h2 {
  font-size: 2.5rem;
  color: #1a237e;
  margin-bottom: 3rem;
  font-weight: 700;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.value-card {
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 16px;
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
}

.value-card h3 {
  font-size: 1.3rem;
  color: #1a237e;
  margin-bottom: 1rem;
  font-weight: 600;
}

.value-card p {
  color: #666;
  line-height: 1.6;
}

/* Founders Section */
.founders-section {
  background: #f8f9fa;
  padding: 80px 0;
  width: 100%;
}

.founders-section h2 {
  font-size: 2.5rem;
  color: #1a237e;
  margin-bottom: 3rem;
  font-weight: 700;
  text-align: center;
}

.founders-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(500px, 1fr));
  gap: 4rem;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 60px;
}

.founder-card {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  width: 100%;
  min-width: 500px;
}

.founder-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.founder-image-container {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.founder-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Lighter overlay */
  z-index: 1;
  transition: background 0.3s ease;
}

.founder-card:hover .founder-image-container::before {
  background: rgba(0, 0, 0, 0.4); /* Slightly darker on hover */
}

.founder-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  transform: scale(1.0); /* Reset initial scale */
  position: relative;
  z-index: 0;
  filter: brightness(1.1); /* Slightly brighten the image */
}

.founder-card:hover .founder-image-container img {
  transform: scale(1.1); /* Subtle zoom on hover */
}

.founder-info {
  position: relative;
  z-index: 2; /* Ensure text stays above the overlay */
  background: white;
  padding: 2.5rem;
  text-align: left;
}

.founder-info h3 {
  font-size: 1.8rem;
  color: #1a237e;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.founder-title {
  color: #4CAF50;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  display: block;
  position: relative;
  padding-bottom: 1rem;
}

.founder-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background: #4CAF50;
}

.founder-description {
  color: #666;
  line-height: 1.8;
  font-size: 1.1rem;
}

/* Responsive Design for Founders Section */
@media (max-width: 1600px) {
  .founders-grid {
    grid-template-columns: repeat(2, minmax(450px, 1fr));
    max-width: 1400px;
    padding: 0 40px;
  }
  
  .founder-card {
    min-width: 450px;
  }
}

@media (max-width: 1200px) {
  .founders-grid {
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    max-width: 1200px;
    padding: 0 30px;
  }
  
  .founder-card {
    min-width: 400px;
  }
}

@media (max-width: 900px) {
  .founders-grid {
    grid-template-columns: 1fr;
    max-width: 700px;
    gap: 3rem;
    padding: 0 20px;
  }

  .founder-card {
    max-width: 100%;
    min-width: unset;
  }

  .founder-image-container {
    height: 450px;
  }
}

@media (max-width: 480px) {
  .founders-grid {
    padding: 0 15px;
  }

  .founder-image-container {
    height: 350px;
  }
}

/* Add smooth scrolling to the whole page */
html {
  scroll-behavior: smooth;
}

/* Optional: Add a transition effect when scrolling to sections */
section {
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .about-hero,
  .stats-section {
    background-attachment: scroll; /* Disable parallax on mobile for better performance */
  }
  
  /* ... keep other mobile styles ... */
}