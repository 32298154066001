h3{
    text-align: center;
}
p {
    font-size: 1rem;
    color: rgb(38, 37, 37);
}

li {
    font-size: 1rem;
    color: rgb(38, 37, 37);
    margin-bottom: 10px;
}