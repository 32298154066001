@keyframes appear {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes appear2 {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes appear3 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.home{
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    margin-top: 5rem;
    width: 100%;
}
.home-section {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    width: 90%;
    text-align: center;
    animation: appear linear;
    animation-timeline:view();
    animation-range:entry 0% cover 50%;
}

.home-section h2 {
    color: #2c3e50;
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: 700;
}

.home-section h3 {
    color: #34495e;
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 500;
}

.home-section p {
    color: #7f8c8d;
    font-size: 16px;
    margin-bottom: 20px;
}

/* Services Section Styles */
.services-section {
  padding: 100px 0;
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.services-header {
  text-align: center;
  margin-bottom: 80px;
}

.section-subtitle {
  display: inline-block;
  background: linear-gradient(45deg, #1a237e, #0d47a1);
  color: white;
  padding: 8px 20px;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.services-header h2 {
  font-size: 2.8rem;
  color: #1a237e;
  margin-bottom: 1rem;
  font-weight: 700;
}

.services-header p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto 2rem;
}

.trust-indicators {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;
}

.trust-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #1a237e;
}

.trust-item svg {
  font-size: 1.5rem;
}

.services-grid {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  padding: 2rem;
}

.service-card {
  background-color: transparent !important;
  border-radius: 20px;
  transition: all 0.4s ease;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  overflow: hidden !important;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);
}

.service-image {
  height: 250px;
  position: relative;
  overflow: hidden;
}

.service-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.service-card:hover .service-image img {
  transform: scale(1.1);
}


.service-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.service-content {
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  border-radius: 30px 30px 0 0;
  z-index: 2;
  width: 100% !important;
}

.service-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(135deg, rgba(52, 152, 219, 0.1), rgba(46, 204, 113, 0.1));
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin: -4rem auto 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  background-color: white;
  
}

.service-content h3 {
  font-size: 1.6rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 1rem;
  text-align: center;
  width: 100% !important;
}

.service-content p {
  color: #64748b;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  text-align: center;
}

.service-features {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

.service-features li {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 0;
  border-bottom: 1px solid #f1f5f9;
  color: #475569;
}

.service-features li:last-child {
  border-bottom: none;
}

.check-icon {
  color: #2ecc71;
  font-size: 1.2rem;
}

.learn-more {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.learn-more:hover {
  gap: 1rem;
  box-shadow: 0 10px 20px rgba(52, 152, 219, 0.2);
}

/* Stats Section */
.stats-section {
  padding: 100px 20px;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  color: white;
}

.stats-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.stat-card {
  text-align: center;
  padding: 2rem;
  background: rgba(255,255,255,0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.stat-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.stat-card h3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.stat-card p {
  font-size: 1.1rem;
  opacity: 0.9;
}

/* CTA Section */
.cta-section {
  padding: 100px 20px;
  background: #2c3e50;
  color: white;
  text-align: center;
  margin: 0;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6)
  ),url("https://images.ctfassets.net/xz1dnu24egyd/3TfaO7bWQQHFFVE2nTy9qu/115df790f0d1232c99bd645950b04aef/cap-hero.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: white;
  color: #2c3e50;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .service-card {
    height: auto;
    min-height: 500px;
  }
}

@media (max-width: 480px) {
  .service-content {
    padding: 1.5rem;
  }

  .service-icon {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
    margin-top: -40px;
  }
}

/* Base Styles */
.home-container {
  overflow-x: hidden;
  background: #ffffff;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 100vh;
  min-height: 800px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6)
    ),
    url("https://www.nexlevtech.com/assets/img/web-dev.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  max-width: 800px;
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem;
}

.gradient-text {
  background: linear-gradient(45deg, #3498db, #2ecc71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero-content p {
  font-size: 1.5rem;
  opacity: 0.9;
  margin-bottom: 2.5rem;
  line-height: 1.4;
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.primary-btn, .secondary-btn {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.primary-btn {
  background: linear-gradient(45deg, #3498db, #2ecc71);
  color: white;
  border: none;
}

.primary-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.secondary-btn {
  background: rgba(255,255,255,0.1);
  border: 2px solid white;
  color: white;
}

.secondary-btn:hover {
  background: white;
  color: #000;
}

/* Benefits Section */
.benefits-section {
  margin-top: -100px;
  position: relative;
  z-index: 3;
  padding: 0 20px;
}

.benefits-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.benefit-card {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-10px);
}

.benefit-icon {
  font-size: 2.5rem;
  color: #3498db;
  margin-bottom: 1.5rem;
}

.benefit-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.benefit-card p {
  color: #666;
  line-height: 1.6;
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
  .hero-content h1 {
    font-size: 3.8rem;
  }

  .benefits-grid {
    gap: 1.5rem;
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    min-height: 600px;
  }

  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1.2rem;
    padding: 0 1rem;
  }

  .benefits-section {
    margin-top: -50px;
  }

  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .benefit-card {
    padding: 2rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    min-height: 500px;
  }

  .hero-content {
    padding: 0 15px;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .benefits-section {
    margin-top: -30px;
    padding: 0 15px;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .benefit-card {
    padding: 1.5rem;
  }

  .benefit-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .benefit-card h3 {
    font-size: 1.3rem;
  }

  .benefit-card p {
    font-size: 0.95rem;
  }
}

/* For very small devices */
@media (max-width: 320px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .benefit-card {
    padding: 1.25rem;
  }
}

/* Services Section */
.services-wrapper {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-tag {
  background: linear-gradient(45deg, #3498db, #2ecc71);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-size: 2.9rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 1rem;
}

.section-header h2 {
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.section-header p {
  color: #666;
  font-size: 1.2rem;
}